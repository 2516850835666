






























































































































































































































































































































import DashBoardModule from "@/store/modules/DashBoard-module";
import { Component, Vue } from "vue-property-decorator";
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
} from "devextreme-vue/chart";
@Component({
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
  },
})
export default class DashGeneral extends Vue {
  created() {
    DashBoardModule.getDashBoards();
  }

  get dash() {
    return DashBoardModule.DashBoard;
  }

  get dash_proyectos() {
    var datos = [];
    datos.push(0);

    var data = DashBoardModule.DashBoard.graf_proyectos_asignadas.map(
      //@ts-ignore
      (x) => x.total
    );
    for (let i = 0; i < data.length; i++) {
      datos.push(data[i]);
    }
    return datos;
  }
  get dash_incidencias() {
    var datos = [];
    datos.push(0);
    var data = DashBoardModule.DashBoard.graf_incidencias_asignadas.map(
      //@ts-ignore
      (x) => x.total
    );
    for (let i = 0; i < data.length; i++) {
      datos.push(data[i]);
    }
    return datos;
  }

  get dash_tareas() {
    var datos = [];
    datos.push(0);
    var data = DashBoardModule.DashBoard.graf_tareas_asignadas.map(
      //@ts-ignore
      (x) => x.total
    );
    for (let i = 0; i < data.length; i++) {
      datos.push(data[i]);
    }
    return datos;
  }

  get dash_general() {
    return DashBoardModule.DashBoard.graf_general;
  }

  architectureSources = [
    { value: "incidencias", name: "Incidencias" },
    { value: "tareas", name: "Tareas" },
    { value: "proyectos", name: "Proyectos" },
  ];
}
